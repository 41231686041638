import { createSlice } from '@reduxjs/toolkit';


const credentialSlice = createSlice({
    name: 'credential',
    initialState: {
        data: {
            access_token: null,
            id_token: null,
            state: null,
            token_type: null,
            expire_in: null,
        },
        isLogged: false,
        status: null,
        tokenDate: new Date(),
    },
    reducers: {
        setCredential: (state, action) => {
            state.data = action.payload;
        },
        setLoginState: (state, action) => {
            state.isLogged = action.payload;
        },
        setStatusState: (state, action) => {
            state.status = action.payload;
        },
        setTokenDate: (state, action) => {
            state.tokenDate = action.payload;
        },
    },
},
);
const { actions, } = credentialSlice;
export const { setCredential, setLoginState, setTokenDate, setStatusState } = actions;
export default credentialSlice.reducer;
