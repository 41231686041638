import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import meetingsReducer from './reducers/meetingsSlice';
import topicsReducer from './reducers/topicsSlice';
import tasksReducer from './reducers/tasksSlice';
import surveysReducer from './reducers/surveysSlice';
import credentialReducer from './reducers/credentialSlice';
import userReducer from './reducers/userSlice';
import chainsReducer from './reducers/chainsSlice';
import participantsReducer from './reducers/participantsSlice';


export const persistConfig = {
  key: 'root',
  storage,
}

const appReducer = combineReducers({
  meetings: meetingsReducer,
  topics: topicsReducer,
  surveys: surveysReducer,
  tasks: tasksReducer,
  chains: chainsReducer,
  credential: credentialReducer,
  user: userReducer,
  participants: participantsReducer,
});

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store)