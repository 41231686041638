import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'


export const getSurveys = createAsyncThunk("surveys/getSurveys", async (data: any) => {
    const { token, meetings } = data;
    let surveys: any[] = [];
    for (let i = 0; i < meetings?.length; i++) {
        let meetingID = meetings.at(i).id;
        let survey: any = fetch(baseAPIurl + "decisions/meetings/" + meetingID, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }).then((res) => res.json());

        if ((await survey).length > 0) {
            surveys?.push((await survey));
        }
    }
    return surveys;
})

export const getSurveysByMeetingsId = createAsyncThunk("surveys/getSurveysByMeetingsId", async (data: any) => {
    const { token, meetingsId } = data;
    return fetch(baseAPIurl + "decisions/meetings", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(meetingsId)
    }).then((res) => res.json())
})


export const createSurvey = createAsyncThunk("surveys/createSurvey", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "decisions", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})

const surveysSlice = createSlice({
    name: 'surveys',
    initialState: {
        data: [],
        surveysByMeetings: [],
        status: null,
        newSurveyStatus: null,
    },
    reducers: {

    },
    extraReducers: {
        [getSurveys.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getSurveys.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getSurveys.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },


        [getSurveysByMeetingsId.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getSurveysByMeetingsId.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.surveysByMeetings = action.payload
        },
        [getSurveysByMeetingsId.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
            state.surveysByMeetings = [];
        },

        [createSurvey.pending as any]: (state: any, action: any) => {
            state.newSurveyStatus = "loading"
        },
        [createSurvey.fulfilled as any]: (state: any, action: any) => {
            state.newSurveyStatus = "success";
        },
        [createSurvey.rejected as any]: (state: any, action: any) => {
            state.newSurveyStatus = "failed"
        },
    }
})

const surveysReducer = surveysSlice.reducer;
export default surveysReducer;