import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Box, Button } from "@fluentui/react-northstar";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
    setLoginState,
    setCredential,
    setTokenDate,
    setStatusState,
} from "../store/reducers/credentialSlice";
import { getUser } from "../store/reducers/userSlice";

export function login(dispatch: any) {
    microsoftTeams.initialize(() => {
        dispatch(setStatusState("loading"));
        microsoftTeams.getContext(function (context) {
            // Generate random state string and store it, so we can verify it in the callback
            let state = _guid(); // _guid() is a helper function in the sample
            localStorage.setItem("simple.state", state);
            localStorage.removeItem("simple.error");
            // Go to the Azure AD authorization endpoint
            let queryParams = {
                client_id: "9d87na2etjfjop8jm1ou8vgq3",
                response_type: "token",
                identity_provider: "aad",
                scope: "email openid profile aws.cognito.signin.user.admin",
                redirect_uri: window.location.origin + "/auth-complete",
                nonce: _guid(),
                state: state,
                login_hint: context.loginHint,
            };
            microsoftTeams.authentication.authenticate({
                isExternal: false,
                url:
                    "https://wgc-goodmeetingdev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?" +
                    toQueryString(queryParams),
                width: 600,
                height: 535,
                successCallback: (result: any) => {
                    dispatch(setCredential(result));
                    dispatch(setLoginState(true));
                    dispatch(setTokenDate(new Date()));
                    const accessToken = "Bearer " + result["access_token"];
                    dispatch(getUser(accessToken));
                    dispatch(setStatusState("success"));
                },
                failureCallback: (reason: any) => {
                    dispatch(setStatusState("rejected"));
                },
            });
        });
    });
}

const LoginPage = () => {
    const dispatch = useDispatch();
    console.log("LOGIN...");
    const status = useSelector(
        (state: RootStateOrAny) => state.credential.status
    );
    return (
        <Box
            styles={(theme) => {
                return { backgroundColor: theme.theme.siteVariables.colors.grey[50] };
            }}
            className="mainLogin"
        >
            <Button
                loading={status === "loading"}
                primary
                onClick={() => {
                    login(dispatch);
                }}
                content="Login"
            />
        </Box>
    );
};

function toQueryString(queryParams: any) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
        encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
}

function _decimalToHex(number: any) {
    let hex = number.toString(16);
    while (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
}

function _guid(): string {
    let cryptoObj = window.crypto;
    if (cryptoObj && cryptoObj.getRandomValues) {
        let buffer = new Uint8Array(16);
        cryptoObj.getRandomValues(buffer);
        buffer[6] |= 0x40;
        buffer[6] &= 0x4f;
        buffer[8] |= 0x80;
        buffer[8] &= 0xbf;
        return (
            _decimalToHex(buffer[0]) +
            _decimalToHex(buffer[1]) +
            _decimalToHex(buffer[2]) +
            _decimalToHex(buffer[3]) +
            "-" +
            _decimalToHex(buffer[4]) +
            _decimalToHex(buffer[5]) +
            "-" +
            _decimalToHex(buffer[6]) +
            _decimalToHex(buffer[7]) +
            "-" +
            _decimalToHex(buffer[8]) +
            _decimalToHex(buffer[9]) +
            "-" +
            _decimalToHex(buffer[10]) +
            _decimalToHex(buffer[11]) +
            _decimalToHex(buffer[12]) +
            _decimalToHex(buffer[13]) +
            _decimalToHex(buffer[14]) +
            _decimalToHex(buffer[15])
        );
    } else {
        let guidHolder = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
        let hex = "0123456789abcdef";
        let r = 0;
        let guidResponse = "";
        for (let i = 0; i < 36; i++) {
            if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
                // each x and y needs to be random
                r = (Math.random() * 16) | 0;
            }
            if (guidHolder[i] === "x") {
                guidResponse += hex[r];
            } else if (guidHolder[i] === "y") {
                // clock-seq-and-reserved first hex is filtered and remaining hex values are random
                r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
                r |= 0x8; // set pos 3 to 1 as 1???
                guidResponse += hex[r];
            } else {
                guidResponse += guidHolder[i];
            }
        }

        return guidResponse;
    }
}

export default LoginPage;
