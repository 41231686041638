import { useEffect } from "react";
import { Card, Box, Header, Text, Avatar } from "@fluentui/react-northstar";

import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import formatDate from "../utils/formatDate";

import MeetingInformation from "./MeetingInformation";
import { getParticipantsById } from "../store/reducers/participantsSlice"

import "../style/MeetingCard.css";




export default function MeetingCard(props: any) {
    const meeting = props.meeting;
    const dateLong = formatDate(new Date(meeting.startTime), "LONG");

    const dispatch = useDispatch();
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);

    useEffect(() => {
        for (let j = 0; j < Object.keys(meeting.participants).length; j++) {
            let participantId = Object.keys(meeting.participants)[j];
            dispatch(getParticipantsById({ token: token, userId: participantId }));
        }

    }, [])

    let participantsList = useSelector((state: RootStateOrAny) => state.participants.participantsList);
    let participants: any = {};
    for (let i = 0; i < Object.keys(participantsList).length; i++) {
        let participandId = Object.keys(participantsList)[i];
        if ((participandId in meeting.participants)) {
            participants[participandId] = participantsList[participandId];
        }
    }
    return (
        <MeetingInformation trigger={
            < Card className="meetingCard" style={{ backgroundColor: '#E2E5FF' }} size="medium" elevated >
                <Header as="h3" content={meeting.title} className="title" />
                <Text style={{ fontSize: 13 }} content={meeting.location} /><br />
                <Text style={{ fontSize: 12 }} content={dateLong} /><br />
                <Text as="div" size="medium" content={meeting.description ? meeting.description : ' '} className="description" />
                <Box className="participantsBox">
                    {
                        Object.keys(participants).slice(0, 10).map(
                            (participant: any, index: any) => (
                                <Avatar size="medium" name={participants[participant].displayName} key={index} />
                            ))
                    }
                </Box>
            </Card >
        }
            meeting={props.meeting}
        />
    );
}
