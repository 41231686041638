export const baseAPIurl = 'https://goodmeeting-api-dev.goodmeeting.biz/goodmeeting/';


export const menuItems = [
    {
        key: 'general',
        content: 'General',
    },
    {
        key: 'topics',
        content: 'Topics',
    },
    {
        key: 'tasks',
        content: 'Tasks',
    },
    {
        key: 'surveys',
        content: 'Surveys',
    },
    {
        key: 'report',
        content: 'Report',
    },
]


export const menuChainItems = [
    {
        key: 'topics',
        content: 'Topics',
    },
    {
        key: 'tasks',
        content: 'Tasks',
    },
    {
        key: 'surveys',
        content: 'Surveys',
    },

]

export const tasksColumns = [
    {
        key: 'todo',
        title: 'To Do',
    },
    {
        key: 'inprogress',
        title: 'In Progress',
    },
    {
        key: 'done',
        title: 'Done',
    },
    {
        key: 'onhold',
        title: 'On Hold',
    },
]



export const statusItem: any = {
    0: "Draft",
    1: "Planned",
    2: "Executed",
    3: "Done"
}


export const headerMeetingtable = {
    key: 1,
    name: 'Name',
    date: 'Date',
    time: 'Time',
    status: 'Status',
    participants: 'Participants',
    location: 'Location'
};


export const seriesItem = ["daily", "weekly", "monthly", "yearly"];


export const headersDashboard = {
    topics:
        [{
            header: 'Name',
            headerMedia: 'Duration',
            key: 1,
        }],
    tasks: [
        {
            header: 'Name',
            headerMedia: 'Priority',
            key: 1,
        },
    ],
    surveys: [
        {
            header: 'Name',
            key: 1,
        },
    ],

};

