import { List, Dialog, Box, Text, Segment, Divider } from "@fluentui/react-northstar";

import { RootStateOrAny, useSelector } from "react-redux";

import { headersDashboard } from "../utils/constants";


function TopicsChainInformation(props: any) {
    const chainsTopics: any = useSelector((state: RootStateOrAny) => state.topics?.topicsByMeetings);
    return (
        <>
            <List items={headersDashboard.topics} className="list" />
            <List>
                {
                    chainsTopics?.map((topic: any, index: any) =>
                        <Dialog
                            key={index}
                            cancelButton="Cancel"
                            header="Topic information"
                            content={
                                <>
                                    <Divider color="brand" />
                                    <Box className="boxTopic" >
                                        <Box className="boxGroup">
                                            <Text content={"Title"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.title} />

                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Duration"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.duration + " min"} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Description"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.description} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Conclusion"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222', minHeight: "60px" }} className="inputMeeting" content={topic.conclusion} />
                                        </Box>
                                    </Box>
                                </>
                            }
                            trigger={<List.Item index={index} header={topic.title} endMedia={topic.duration + " min"} className="listItemChain" />}
                        />)
                }
            </List>
        </>
    );
}

export default TopicsChainInformation;