import { Box, Text } from "@fluentui/react-northstar";

import "../style/Taskboard.css";
import formatDate from "../utils/formatDate";
import { getColor } from "../utils/getColor";


export default function TaskCard(props: any) {
    const task = props.task;
    return (
        < Box {...props} className="taskCard" styles={(theme: any) => { return { backgroundColor: theme.theme.siteVariables.colors.grey[100] }; }}  >
            <Text as="div" className="taskTitle" content={task.title} />
            <Text as="div" className="taskDate" content={formatDate(new Date(task.dueDate), "SHORT")} />
            <Text as="div" content={task.description ? task.description : ' '} className="taskDescription" />
            <Text className="taskPriority">Priority:</Text>
            <Text className="taskPriority" styles={{ color: getColor(task.priority), fontWeight: '600' }} content={" " + task.priority} />
        </Box >
    );
}
