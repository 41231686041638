import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const getUser = createAsyncThunk("user/getUser", async (accessToken: string) => {
    return fetch("https://wgc-goodmeetingdev.auth.eu-central-1.amazoncognito.com/oauth2/userInfo", {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: accessToken,
        },
    }).then((res) => res.json())
})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {
            sub: null,
            email_verified: null,
            given_name: null,
            family_name: null,
            email: null,
            username: null,
        },
        status: null,
    },
    reducers: {

    },
    extraReducers: {
        [getUser.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getUser.fulfilled as any]: (state: any, action: any) => {
            state.status = "success";
            state.data = action.payload
        },
        [getUser.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },
    }
})

const userReducer = userSlice.reducer;
export default userReducer;