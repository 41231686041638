import { EditIcon, AcceptIcon, ApprovalsAppbarIcon, ToDoListIcon } from '@fluentui/react-icons-northstar'

export function getIcon(status) {
    status = status?.toString();
    switch (status) {
        case "0":
            return <EditIcon style={{ stroke: 'lightgray' }} outline size="large" className="iconItem" />;
        case "1":
            return <ToDoListIcon style={{ stroke: '#464EB8' }} outline size="large" className="iconItem" />;
        case "2":
            return <ApprovalsAppbarIcon style={{ stroke: '#FDDA0D' }} outline size="large" className="iconItem" />;
        case "3":
            return <AcceptIcon style={{ stroke: '#0DBD0D' }} outline size="large" className="iconItem" />;
        default:
            return "";
    }
}