import React from "react";

import * as microsoftTeams from "@microsoft/teams-js";


class AuthComplete extends React.Component<{}, { userData: any, hashParams: any }> {
    public async componentDidMount() {
        microsoftTeams.initialize(() => {
            localStorage.removeItem("simple.error");
            let hashParams = this.getHashParameters();
            if (hashParams["error"]) {
                // Authentication/authorization failed
                localStorage.setItem("simple.error", JSON.stringify(hashParams));
                microsoftTeams.authentication.notifyFailure(hashParams["error"]);
            } else if (hashParams["access_token"]) {
                // Get the stored state parameter and compare with incoming state
                let expectedState = localStorage.getItem("simple.state");
                if (expectedState !== hashParams["state"]) {
                    // State does not match, report error
                    localStorage.setItem("simple.error", JSON.stringify(hashParams));
                    microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
                } else {
                    // Success -- return token information to the parent page
                    microsoftTeams.authentication.notifySuccess(hashParams);
                }
            } else {
                // Unexpected condition: hash does not contain error or access_token parameter
                localStorage.setItem("simple.error", JSON.stringify(hashParams));
                microsoftTeams.authentication.notifySuccess(hashParams);
                microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
            }
        });
    }

    getHashParameters(): any {
        let hashParams: any = {};
        // eslint-disable-next-line no-restricted-globals
        location.hash.substr(1).split("&").forEach(function (item: string) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            hashParams[k] = v;
        });
        return hashParams;
    }

    render() {
        return (
            <div>
                AUTH COMPLETED
            </div>
        );
    }
}

export default AuthComplete;