import { useEffect, useState } from "react";
import { Box, Skeleton } from "@fluentui/react-northstar";
import _ from 'lodash';

import { connect, RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { getTasks, updateTask } from "../store/reducers/tasksSlice";

import HeaderBar from "../components/HeaderBar";
import { login } from "./LoginPage";

import { tasksColumns } from "../utils/constants";


import "../style/Taskboard.css";
import TaskColumn from "../components/TaskColumn";


function Taskboard(props: any) {
  //Get new token if last has expired
  const dispatch = useDispatch();
  let tokenDate = useSelector((state: RootStateOrAny) => state?.credential?.tokenDate);
  tokenDate = new Date(tokenDate);
  const expires_in = useSelector((state: RootStateOrAny) => state?.credential?.data.expires_in);
  const timeOffset = 300;
  if ((new Date()).getTime() > tokenDate.getTime() + (expires_in - timeOffset) * 1000) {
    login(dispatch);
    console.log("RE LOGIN");
  }


  console.log("TASKBOARD");
  const [searchText, setSearchText] = useState("");
  const [selectedCol, setSelectedCol] = useState(0);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Object>();
  const [updatedStatus, setUpdatedStatus] = useState(true);
  const [taskIndex, setTaskIndex] = useState(null);

  let status = "loading";
  status = useSelector((state: RootStateOrAny) => state.tasks?.status);
  const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
  const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);

  function updateStatus(key: any) {
    let newTask: any = { ...selectedTask, status: tasksColumns[key].title };
    props.updateTask(token, newTask);
    setUpdatedStatus(false);
    setTimeout(() => {
      props.updateData(token, userID)
    }, 600);
    setTaskIndex(null);
  }

  useEffect(() => {
    props.updateData(token, userID);
  }, [props, token, userID])


  return (
    <>
      <HeaderBar setSearchText={setSearchText} />
      <Box className="mainTaskboard" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.white } }} onClick={() => {
        if (isSelecting) {
          setIsSelecting(false)
          setTaskIndex(null);
        }
      }}>
        {(status !== "success" && updatedStatus) ?
          _.times(4, index => (
            <Skeleton key={index} animation="pulse" className="taskColumn" style={{ borderRight: index === 4 ? "" : "solid 1px #ccc" }}>
              <Skeleton.Line width='40%' height="30px" style={{ margin: "15px 0 18px 0" }} />
              {
                _.times(2, index => (
                  <Skeleton.Shape key={index} className="taskCard" width='90%' style={{ margin: 20 }} />
                ))
              }

            </Skeleton>
          ))
          : tasksColumns.map(
            (taskColumn: any, index: any) => {
              return (
                <TaskColumn index={index} taskColumn={taskColumn} selectedCol={selectedCol} searchText={searchText} isSelecting={isSelecting}
                  setIsSelecting={setIsSelecting} setSelectedCol={setSelectedCol} setSelectedTask={setSelectedTask} taskIndex={taskIndex} setTaskIndex={setTaskIndex}
                  updateStatus={updateStatus}
                />
              );
            }
          )
        }
      </Box>
    </>
  );
}

const mapDispatch = (dispatch: any) => {
  return {
    updateData: (token: string, userID: string) => {
      dispatch(getTasks({ token, userID }));
    },
    updateTask: (token: string, payload: any) => {
      dispatch(updateTask({ token, payload }));
    },
    logout: () => dispatch({ type: "USER_LOGOUT" }),
  }
}

export default connect(null, mapDispatch)(Taskboard);