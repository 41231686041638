/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Box, Menu, tabListBehavior } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasksByMeetingsId } from "../store/reducers/tasksSlice";
import { getTopicsByMeetingsId } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";

import sortArray from "../utils/sortArray";

import HeaderBar from "../components/HeaderBar";
import MeetingTable from "../components/MeetingTable";
import { login } from "./LoginPage";

import "../style/Chains.css";
import { getSurveysByMeetingsId } from "../store/reducers/surveysSlice";
import MenuChain from "../components/MenuChain";

function Chains(props: any) {
  //Get new token if last has expired
  const dispatch = useDispatch();
  let tokenDate = useSelector((state: RootStateOrAny) => state?.credential?.tokenDate);
  tokenDate = new Date(tokenDate);
  const expires_in = useSelector((state: RootStateOrAny) => state?.credential?.data.expires_in);
  const timeOffset = 300;
  if ((new Date()).getTime() > tokenDate.getTime() + (expires_in - timeOffset) * 1000) {
    login(dispatch);
    console.log("RE LOGIN");
  }

  console.log("CHAINS");
  const [searchText, setSearchText] = useState("");

  const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
  const meetings: any = useSelector((state: RootStateOrAny) => state.meetings?.data);
  const chains: any = useSelector((state: RootStateOrAny) => state.chains?.data);

  let activeChainId = "";

  const [activeChainName, setActiveChainName] = useState<any>("")
  const activeChain = chains?.find((item: any) => item.title === activeChainName);

  if (activeChain) {
    activeChainId = activeChain.id;
  }

  let chainsMeetings = meetings?.filter((item: any) => item.chain_id === activeChainId && item.title.toLowerCase().includes(searchText.toLowerCase()));
  chainsMeetings = sortArray(chainsMeetings);

  function update() {
    props.updateData(token, "");
  }
  let meetingsIdArr = chainsMeetings?.map((meeting: any) => meeting.id);

  useEffect(() => {
    update();
  }, [])
  return (
    <>
      <HeaderBar setSearchText={setSearchText} />
      <Box className="mainChain" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] } }}>
        <MenuChain chains={chains} meetings={meetings} setActiveChainName={setActiveChainName} searchText={searchText} updateData={props.updateData} />
        <MeetingTable meetings={chainsMeetings} updateData={update} isChain={chains.length > 0} chainName={activeChainName} />
      </Box>
    </>
  );
}

const mapDispatch = (dispatch: any) => {
  return {
    updateData: (token: string, meetingsId: string) => {
      dispatch(getMeetings(token));
      dispatch(getTasksByMeetingsId({ token, meetingsId }));
      dispatch(getTopicsByMeetingsId({ token, meetingsId }));
      dispatch(getSurveysByMeetingsId({ token, meetingsId }));
      dispatch(getChains(token));
    },
    logout: () => dispatch({ type: "USER_LOGOUT" }),
  }
}
export default connect(null, mapDispatch)(Chains);