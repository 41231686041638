import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'


export const getParticipants = createAsyncThunk("participants/getParticipants", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "users?search=" + payload, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json())
})

export const getParticipantsById = createAsyncThunk("participants/getParticipantsById", async (data: any) => {
    const { token, userId } = data;
    let participant = fetch(baseAPIurl + "users/" + userId, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json());
    return { userId: userId, participant: await participant };
})


const participantsSlice = createSlice({
    name: 'participants',
    initialState: {
        data: [],
        status: null,
        participantsList: {},
    },
    reducers: {

    },
    extraReducers: {
        [getParticipants.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getParticipants.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getParticipants.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },

        [getParticipantsById.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getParticipantsById.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            const { userId, participant } = action.payload
            let participantsList = { ...state.participantsList }
            participantsList[userId] = participant
            state.participantsList = participantsList
        },
        [getParticipantsById.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },
    }
})

const participantsReducer = participantsSlice.reducer;
export default participantsReducer;