import { useEffect, useState } from "react";
import { Box, Menu, tabListBehavior } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasks } from "../store/reducers/tasksSlice";
import { getTopics } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";

import sortArray from "../utils/sortArray";
import { seriesItem } from "../utils/constants";

import HeaderBar from "../components/HeaderBar";
import MeetingTable from "../components/MeetingTable";
import { login } from "./LoginPage";

import "../style/Chains.css";



function Series(props: any) {
  //Get new token if last has expired
  const dispatch = useDispatch();
  let tokenDate = useSelector((state: RootStateOrAny) => state?.credential?.tokenDate);
  tokenDate = new Date(tokenDate);
  const expires_in = useSelector((state: RootStateOrAny) => state?.credential?.data.expires_in);
  const timeOffset = 300;
  if ((new Date()).getTime() > tokenDate.getTime() + (expires_in - timeOffset) * 1000) {
    login(dispatch);
    console.log("RE LOGIN");
  }

  console.log("SERIES");
  const [searchText, setSearchText] = useState("");
  const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
  const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);

  function update() {
    props.updateData(token, userID);
  }
  useEffect(() => {
    update();
  }, [])


  const meetings: any = useSelector((state: RootStateOrAny) => state.meetings?.data);
  const firstSerie = seriesItem.at(0);
  const [activeSerieName, setActiveSerieName] = useState<any>(firstSerie);
  let serieMeetings = meetings?.filter((item: any) => item.serialType === activeSerieName && item.title.toLowerCase().includes(searchText.toLowerCase()));
  serieMeetings = sortArray(serieMeetings, "asc");

  return (
    <>
      <HeaderBar setSearchText={setSearchText} />
      <Box className="mainChain" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] } }}>
        <Menu defaultActiveIndex={0} accessibility={tabListBehavior} primary vertical className="menuLeft" style={{ borderRadius: 0, borderTop: "none", paddingTop: 0, }}>
          {seriesItem.map((serie: any, index: any) => {
            return (

              <Menu.Item index={index} key={index} onClick={() => setActiveSerieName(serie)} >
                <Menu.ItemContent className="itemContent">{serie.charAt(0).toUpperCase() + serie.slice(1) + " (" + meetings?.filter((item: any) => item.serialType === serie).length + ")"}</Menu.ItemContent>
              </Menu.Item>
            )
          }
          )}
        </Menu>
        <MeetingTable meetings={serieMeetings} updateData={update} />

      </Box>
    </>
  );
}

const mapDispatch = (dispatch: any) => {
  return {
    updateData: (token: string, userID: string) => {
      dispatch(getMeetings(token));
      dispatch(getTasks({ token, userID }));
      dispatch(getTopics({ token, userID }));
      dispatch(getChains(token));
    },
    logout: () => dispatch({ type: "USER_LOGOUT" }),
  }
}
export default connect(null, mapDispatch)(Series);