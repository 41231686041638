import { List, Dialog, Divider, Segment, Box, Text } from "@fluentui/react-northstar";
import { useEffect } from "react";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getParticipantsById } from "../store/reducers/participantsSlice";

import { headersDashboard } from "../utils/constants";
import formatDate from "../utils/formatDate";


function TasksChainInformation(props: any) {
    const chainsTasks: any = useSelector((state: RootStateOrAny) => state.tasks?.tasksByMeetings);
    const dispatch = useDispatch();
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    let participantsList = useSelector((state: RootStateOrAny) => state.participants.participantsList);
    useEffect(() => {
        chainsTasks.map((task: any) => dispatch(getParticipantsById({ token: token, userId: task.responsible })));
    }, [dispatch, chainsTasks, token])
    return (
        <>
            <List items={headersDashboard.tasks} className="list" />
            <List>
                {
                    chainsTasks?.map((task: any, index: any) => {

                        return (
                            <Dialog
                                key={index}
                                cancelButton="Close"
                                header="Task information"
                                content={
                                    <>
                                        <Divider color="brand" />
                                        <Box className="boxTopic" >
                                            <Box className="boxGroup">
                                                <Text content={"Title"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={task.title} />

                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Assigned user"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={participantsList[task.responsible].displayName} />
                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Description"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={task.description} />
                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Priority"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={task.priority} />
                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Due date"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={formatDate(new Date(task.dueDate), "SHORT")} />
                                            </Box>
                                        </Box>
                                    </>
                                }
                                trigger={<List.Item index={index} header={task.title} content={formatDate(new Date(task.dueDate), "SHORT")} headerMedia={task.priority} className="listItemChain" />}
                            />)
                    })
                }
            </List>
        </>
    );
}

export default TasksChainInformation;