import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'


export const getTasks = createAsyncThunk("tasks/getTasks", async (data: any) => {
    const { token, userID } = data;
    return fetch(baseAPIurl + "tasks/users/" + userID, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json())
})

export const getTasksByMeetingsId = createAsyncThunk("tasks/getTasksByMeetingsId", async (data: any) => {
    const { token, meetingsId } = data;
    return fetch(baseAPIurl + "tasks/meetings", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(meetingsId)
    }).then((res) => res.json())
})

export const updateTask = createAsyncThunk("tasks/updateTask", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "tasks/" + payload?.id, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})


export const createTask = createAsyncThunk("tasks/createTask", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "tasks", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})


const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        data: [],
        tasksByMeetings: [],
        status: null,
        updateTaskStatus: null,
        newTaskStatus: null,
    },
    reducers: {

    },
    extraReducers: {
        [getTasks.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getTasks.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getTasks.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },

        [getTasksByMeetingsId.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getTasksByMeetingsId.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.tasksByMeetings = action.payload
        },
        [getTasksByMeetingsId.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
            state.tasksByMeetings = [];
        },


        [updateTask.pending as any]: (state: any, action: any) => {
            state.updateTaskStatus = "loading"
        },
        [updateTask.fulfilled as any]: (state: any, action: any) => {
            state.updateTaskStatus = "success";
        },
        [updateTask.rejected as any]: (state: any, action: any) => {
            state.updateTaskStatus = "failed"
        },

        [createTask.pending as any]: (state: any, action: any) => {
            state.newTaskStatus = "loading"
        },
        [createTask.fulfilled as any]: (state: any, action: any) => {
            state.newTaskStatus = "success";
        },
        [createTask.rejected as any]: (state: any, action: any) => {
            state.newTaskStatus = "failed"
        },

    }
})

const tasksReducer = tasksSlice.reducer;
export default tasksReducer;