/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Header, List } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasks } from "../store/reducers/tasksSlice";
import { getTopics } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";
import { getSurveys } from "../store/reducers/surveysSlice";

import { headersDashboard } from "../utils/constants";

import { login } from "./LoginPage";
import HeaderBar from "../components/HeaderBar";
import MeetingsList from "../components/MeetingsList";
import TopicsList from "../components/TopicsList";
import SurveysList from "../components/SurveysList";
import TasksList from "../components/TasksList";

import "../style/Dashboard.css";




function Dashboard(props: any) {
  console.log("DASHBOARD");
  const [searchText, setSearchText] = useState("");
  const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
  const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);
  const meetings = useSelector((state: RootStateOrAny) => state.meetings.data);

  useEffect(() => {
    props.updateData(token, userID, meetings);
  }, [props, token, userID])

  const dispatch = useDispatch();

  let tokenDate = useSelector((state: RootStateOrAny) => state?.credential?.tokenDate);
  tokenDate = new Date(tokenDate);
  const expires_in = useSelector((state: RootStateOrAny) => state?.credential?.data.expires_in);
  const timeOffset = 300;
  if ((new Date()).getTime() > tokenDate.getTime() + (expires_in - timeOffset) * 1000) {
    login(dispatch);
    console.log("RE LOGIN");
  }
  return (
    <Box className="dashboardContainer">
      <HeaderBar setSearchText={setSearchText} />
      <Box className="main" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.white }; }}>
        <Box className="containerLW">
          <Box className="containerST">
            <Box className="containerSTL" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] }; }}>
              <Header as="h2" content="Upcoming Meetings" styles={theme => { return { color: theme.theme.siteVariables.colors.brand[1000] }; }} />
              <MeetingsList searchText={searchText} />
              <Box className="viewAll">
              </Box>
            </Box>
          </Box>
          <Box className="containerSB" >
            <Box className="containerSBL" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] }; }}>
              <Header as="h2" content="Assigned Topics" styles={theme => { return { color: theme.theme.siteVariables.colors.brand[1000] }; }} />
              <List items={headersDashboard.topics} className="list" />
              <TopicsList />
            </Box>
            <Box className="containerSBL" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] }; }}>
              <Header as="h2" content="Pending Surveys" styles={theme => { return { color: theme.theme.siteVariables.colors.brand[1000] }; }} />
              <List items={headersDashboard.surveys} className="list" />
              <SurveysList />
            </Box>
            <Box className="containerSBL2" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] }; }}>
              <Header as="h2" content="Assigned Tasks" styles={theme => { return { color: theme.theme.siteVariables.colors.brand[1000] }; }} />
              <List items={headersDashboard.tasks} className="list" />
              <TasksList />
            </Box>
          </Box>
        </Box>
        <Box className="containerLH">
          <Box className="containerSLL" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] }; }}>
            <Header as="h2" content="Assigned Tasks" styles={theme => { return { color: theme.theme.siteVariables.colors.brand[1000] }; }} />
            <List items={headersDashboard.tasks} className="list" />
            <TasksList />
          </Box>
        </Box>
      </Box >
    </Box>
  );
}
const mapDispatch = (dispatch: any) => {
  return {
    updateData: (token: string, userID: string, meetings: any) => {
      dispatch(getMeetings(token));
      dispatch(getTasks({ token, userID }));
      dispatch(getTopics({ token, userID }));
      dispatch(getChains(token));
      dispatch(getSurveys({ token, meetings }))
    },
    logout: () => dispatch({ type: "USER_LOGOUT" }),
  }
}
export default connect(null, mapDispatch)(Dashboard);