import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsThemeContext, getContext, ThemeStyle } from 'msteams-ui-components-react';

import { useSelector, RootStateOrAny } from "react-redux";

import LoginPage from "./containers/LoginPage";
import Dashboard from "./containers/Dashboard";
import Chains from "./containers/Chains";
import Series from "./containers/Series";
import Upcoming from "./containers/Upcoming";
import Taskboard from "./containers/Taskboard";
import History from "./containers/History";
import AuthComplete from "./containers/AuthComplete";


const App = () => {
  microsoftTeams.initialize();
  const isLogged = useSelector((state: RootStateOrAny) => state.credential.isLogged);
  const context = getContext({
    baseFontSize: 12,
    style: ThemeStyle.Light
  });
  return (
    <Provider theme={teamsTheme} >
      <div className="defaultContainer">
        <TeamsThemeContext.Provider value={context}>
          <Suspense fallback={"loading"}>
            <Router>
              <Switch >
                <Route path="/dashboard" component={isLogged ? Dashboard : LoginPage} />
                <Route path="/chains" component={isLogged ? Chains : LoginPage} />
                <Route path="/series" component={isLogged ? Series : LoginPage} />
                <Route path="/upcoming" component={isLogged ? Upcoming : LoginPage} />
                <Route path="/taskboard" component={isLogged ? Taskboard : LoginPage} />
                <Route path="/history" component={isLogged ? History : LoginPage} />
                <Route path="/auth-complete" component={AuthComplete} />
                <Route exact path='*' render={({ location }) => {
                  return (<Redirect to={location.hash.replace('#', '')} />)
                }} />
              </Switch>
            </Router>
          </Suspense>
        </TeamsThemeContext.Provider>
      </div>
    </Provider>
  );
}

export default App;