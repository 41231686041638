import { useEffect } from "react";
import { Box, Text, Segment, Input, Dropdown, Datepicker } from "@fluentui/react-northstar";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { getParticipantsById } from "../store/reducers/participantsSlice";

import formatDate from "../utils/formatDate";

import "../style/MeetingCard.css";


function GeneralInformation(props: any) {
    let edit = props.edit;
    let meeting = props.meeting;
    let isConfirming = props.isConfirming;
    let setState = props.setState;
    const { title, description, location, meetingStartTime, meetingEndTime, startTime, endTime, participants } = props.meetingData;
    const dateDay = formatDate(new Date(meeting.startTime), "SHORT");

    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);

    let participantsSearched = useSelector((state: RootStateOrAny) => state.participants.data);
    let participantsStatus = useSelector((state: RootStateOrAny) => state.participants.status);

    startTime.setHours(parseInt(meetingStartTime.split(":")[0]), parseInt(meetingStartTime.split(":")[1]), 0);
    endTime.setHours(parseInt(meetingEndTime.split(":")[0]), parseInt(meetingEndTime.split(":")[1]), 0);

    const onChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "meetingStartTime") {
            startTime.setHours(parseInt(value.split(":")[0]), parseInt(value.split(":")[1]), 0);
        }
        if (name === "meetingEndTime") {
            endTime.setHours(parseInt(value.split(":")[0]), parseInt(value.split(":")[1]), 0);
        }
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const onChangeParticipants = (e: any, data: any) => {
        let search = data.searchQuery;
        props.getParticipants(token, search);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        for (let j = 0; j < Object.keys(participants).length; j++) {
            let participantId = Object.keys(participants)[j];
            dispatch(getParticipantsById({ token: token, userId: participantId }));
        }

    }, [])

    let participantsList = useSelector((state: RootStateOrAny) => state.participants.participantsList);
    let participantsMeeting: any = {};
    for (let i = 0; i < Object.keys(participantsList).length; i++) {
        let participandId = Object.keys(participantsList)[i];
        if ((participandId in participants)) {
            participantsMeeting[participandId] = participantsList[participandId];
        }
    }
    const addParticipants = (e: any, data: any) => {
        let listParticipants: any = {};
        let values = data.value;
        for (let i = 0; i < values.length; i++) {
            if (!(values[i].key in participants)) {
                listParticipants[values[i].key] = 2;
            } else {
                listParticipants[values[i].key] = participants[values[i].key];
            }
        }
        setState((prevState: any) => ({ ...prevState, participants: listParticipants }));
    };

    return (
        <>
            {
                edit ? (
                    <>
                        <Box className="boxMeeting" >
                            <Box className="boxGroup">
                                <Text content={"Meeting name"} className="titleMeeting" />
                                <Input value={title} name="title" required fluid onChange={onChange} error={isConfirming && !title} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Location"} className="titleMeeting" />
                                <Input value={location} name="location" required fluid onChange={onChange} error={isConfirming && !location} />
                            </Box>
                            <Box className="boxGroup">
                                <Box className="boxDouble">
                                    <Box className="smallBox">
                                        <Text content={"When"} className="titleMeeting" />
                                        <Box>
                                            <Datepicker defaultSelectedDate={new Date(meeting.startTime)} minDate={new Date()} today={new Date()} allowManualInput={false} className="datePicker" onDateChange={(e, value: any) => {
                                                setState((prevState: any) => ({ ...prevState, startTime: new Date(value.itemProps.value.key) }));
                                                setState((prevState: any) => ({ ...prevState, endTime: new Date(value.itemProps.value.key) }));
                                            }} />
                                        </Box>

                                    </Box>
                                    <Box className="smallBoxTime">
                                        <Text content={"From"} className="titleMeeting" />
                                        <input value={meetingStartTime} className={isConfirming && meetingStartTime === null ? "timePickerError" : "timePicker"} name="meetingStartTime" type="time" onChange={onChange} />
                                    </Box>
                                    <Box className="smallBoxTime">
                                        <Text content={"To"} className="titleMeeting" />
                                        <input value={meetingEndTime} className={startTime.getTime() >= endTime.getTime() ? "timePickerError" : "timePicker"} name="meetingEndTime" type="time" onChange={onChange} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Participants"} className="titleMeeting" />
                                <Dropdown
                                    //error={isConfirming && Object.keys(participantsSearched).length <= 1}
                                    multiple
                                    fluid
                                    search
                                    value={
                                        Object.keys(participantsMeeting).map(
                                            participant => ({ header: participantsMeeting[participant].displayName, key: participant })
                                        )}
                                    items={
                                        participantsSearched.slice(0, 3).map((item: any) => ({ header: item.displayName, key: item.id }))
                                    }
                                    onChange={addParticipants}
                                    onSearchQueryChange={onChangeParticipants}
                                    position="below"
                                    placeholder="Start typing a name"
                                    noResultsMessage="We couldn't find any matches."
                                    a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                                    toggleIndicator={<></>}
                                    loadingMessage="Loading..."
                                    loading={participantsStatus === "loading"}
                                />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Description"} className="titleMeeting" />
                                <Input value={description} name="description" required fluid onChange={onChange} error={isConfirming && !description} />
                            </Box>
                        </Box>
                    </>
                ) :
                    (<>

                        <Box className="boxMeeting" >
                            <Box className="boxGroup">
                                <Text content={"Meeting name"} className="titleMeeting" />
                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={meeting.title} />

                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Location"} className="titleMeeting" />
                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={meeting.location} />
                            </Box>
                            <Box className="boxGroup">
                                <Box className="boxDouble">
                                    <Box style={{ width: '30%' }}>
                                        <Text content={"When"} className="titleMeeting" />
                                        <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={dateDay} />
                                    </Box>
                                    <Box style={{ width: '30%' }}>
                                        <Text content={"From"} className="titleMeeting" />
                                        <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={meetingStartTime} />
                                    </Box>
                                    <Box style={{ width: '30%' }}>
                                        <Text content={"To"} className="titleMeeting" />
                                        <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={meetingEndTime} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Participants"} className="titleMeeting" />
                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={
                                    Object.keys(participantsMeeting).map(
                                        participant =>
                                            participantsMeeting[participant].displayName + (participant !== Object.keys(participantsMeeting).slice(-1)[0] ? ", " : "")
                                    )} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Description"} className="titleMeeting" />
                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={meeting.description} />
                            </Box>
                        </Box>
                    </>)
            }
        </>
    );
}

export default GeneralInformation;

