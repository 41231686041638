import { List, Skeleton } from "@fluentui/react-northstar";
import _ from 'lodash'

import { useSelector } from "react-redux";


export default function TopicsList() {
    const topics = useSelector((state) => state.topics?.data);
    const status = useSelector((state) => state.topics?.status);
    return (
        <List>
            {(status !== "success") ?
                _.times(3, index => (
                    <List.Item key={index} header={<Skeleton animation="wave"><Skeleton.Line width="120px" /></Skeleton>} endMedia={<Skeleton animation="pulse"><Skeleton.Line width="60px" /></Skeleton>} />
                ))
                : (topics?.length > 0) ? topics.slice(0, 5).map(
                    (item, index) => (
                        <List.Item key={index} header={item.title} endMedia={item.duration + " min"} onClick={() => { }} className="listItem" />
                    ))
                    : ""}
        </List>
    )
}