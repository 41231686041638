import { List, Skeleton } from "@fluentui/react-northstar";
import _ from 'lodash'

import { useSelector } from "react-redux";

import formatDate from "../utils/formatDate";


export default function TasksList() {
    const tasks = useSelector((state) => state.tasks?.data);
    const status = useSelector((state) => state.tasks?.status);

    return (
        <List>
            {(status !== "success") ?
                _.times(5, index => (
                    <List.Item key={index} header={<Skeleton animation="pulse"><Skeleton.Line width="120px" /></Skeleton>} content={<Skeleton animation="wave"><Skeleton.Line width="80px" /></Skeleton>} headerMedia={<Skeleton animation="wave"><Skeleton.Line width="60px" /></Skeleton>} />
                ))
                : (tasks?.length > 0) ? tasks.slice(0, 8).map(
                    (item, key) => ((new Date(item.dueDate) >= new Date() || true) ?
                        <List.Item key={key} header={item.title} content={formatDate(new Date(item.dueDate), "SHORT")} headerMedia={item.priority} className="listItem" />
                        : "")
                ) : ""}
        </List>
    )
}