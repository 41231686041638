import { useEffect, useState } from "react";
import { Dialog, List, Divider, Box, Segment, Text, AddIcon, Button, Input, Datepicker, Dropdown } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { getParticipantsById } from "../store/reducers/participantsSlice";
import { createTask, getTasks } from "../store/reducers/tasksSlice";

import { headersDashboard } from "../utils/constants";
import formatDate from "../utils/formatDate";
import { getColor } from "../utils/getColor";


function TasksInformation(props: any) {
    let meetingId = props.meeting.id;
    let participants = props.meeting.participants;
    let tasks = useSelector((state: RootStateOrAny) => state.tasks?.data);
    tasks = tasks.filter((item: any) => item.meetingID === meetingId);
    const dispatch = useDispatch();

    const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    let participantsList = useSelector((state: RootStateOrAny) => state.participants.participantsList);

    const initialTask = {
        title: "",
        description: "",
        status: "To Do",
        priority: "Low",
        meetingID: meetingId,
        responsible: [Object.keys(participants)[0]],
        dueDate: new Date(),
    };

    const [
        { title, description, status, priority, responsible, dueDate },
        setState
    ] = useState(initialTask);

    const onChange = (e: any) => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const setTask = () => {
        let payload = {
            title: title,
            description: description,
            status: status,
            priority: priority,
            meetingID: meetingId,
            responsible: responsible,
            dueDate: dueDate.toISOString(),
        }
        props.createNewTask(token, payload);
        setTimeout(() => props.updateData(token, userID), 2000);
    }

    const clearState = () => {
        setState({ ...initialTask });
    };

    const onChangePriority = (e: any, data: any) => {
        let value = data.value;
        setState((prevState: any) => ({ ...prevState, priority: value }));
    };

    useEffect(() => {
        tasks.map((task: any) => dispatch(getParticipantsById({ token: token, userId: task.responsible })));
    }, [dispatch, tasks, token])


    return (
        <>
            <List items={headersDashboard.tasks} className="list" />
            <List>
                {
                    tasks?.map((task: any, index: any) =>

                        <Dialog
                            key={index}
                            cancelButton="Close"
                            header="Task information"
                            content={
                                <>
                                    <Divider color="brand" />
                                    <Box className="boxTopic" >
                                        <Box className="boxGroup">
                                            <Text content={"Title"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={task.title} />

                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Assigned user"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={participantsList[task.responsible].displayName} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Description"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={task.description} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Priority"} className="titleMeeting" />
                                            <Segment disabled style={{ color: getColor(task.priority) }} className="inputMeeting" content={task.priority} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Due date"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={formatDate(new Date(task.dueDate), "SHORT")} />
                                        </Box>
                                    </Box>
                                </>
                            }
                            trigger={<List.Item index={index} header={task.title} content={formatDate(new Date(task.dueDate), "SHORT")} headerMedia={task.priority} className="listItemChain" />}
                        />)
                }
            </List>
            <Dialog
                cancelButton="Cancel"
                confirmButton="Save"
                onCancel={() => {
                    clearState();
                }}
                onConfirm={() => {
                    setTask();
                    clearState();
                }}
                header="New task"
                content={
                    <>
                        <Divider />
                        <Box className="boxMeeting" >
                            <Box className="boxGroup">
                                <Text content={"Task name"} className="titleMeeting" />
                                <Input name="title" required fluid onChange={onChange} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Description"} className="titleMeeting" />
                                <Input name="description" required fluid onChange={onChange} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Priority"} className="titleMeeting" />
                                <Dropdown
                                    fluid
                                    items={
                                        ["Low", "Medium", "High"]
                                    }
                                    onChange={onChangePriority}
                                    position="below"
                                    placeholder="Low"
                                />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Due date"} className="titleMeeting" />
                                <Box>
                                    <Datepicker defaultSelectedDate={new Date(props.meeting.startTime)} minDate={new Date()} today={new Date()} allowManualInput={false} className="datePicker" onDateChange={(e, value: any) => {
                                        setState((prevState: any) => ({ ...prevState, dueDate: new Date(value.itemProps.value.key) }));
                                    }} />
                                </Box>

                            </Box>
                            {/*
                        <Box className="boxGroup">
                            <Text content={"Participants"} className="titleMeeting" />
                            <Dropdown
                                //error={isConfirming && Object.keys(participants).length <= 1}
                                multiple
                                fluid
                                search
                                items={
                                    participantsSearched.slice(0, 3).map((item: any) => ({ header: item.displayName, key: item.id }))
                                }
                                onChange={addParticipants}
                                onSearchQueryChange={onChangeParticipants}
                                position="below"
                                placeholder="Start typing a name"
                                noResultsMessage="We couldn't find any matches."
                                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                                toggleIndicator={<></>}
                                loadingMessage="Loading..."
                                loading={participantsStatus === "loading"}
                            />
                        </Box>*/}

                        </Box>
                    </>
                }
                trigger={
                    <Button primary style={{ marginTop: 20 }} icon={<AddIcon />} iconPosition="after" content="New task" />
                } />

        </>
    );
}


const mapDispatch = (dispatch: any) => {
    return {
        createNewTask: (token: string, payload: any) => {
            dispatch(createTask({ token, payload }));
        },

        updateData: (token: string, userID: string) => {
            dispatch(getTasks({ token, userID }));
        },
    }
}
export default connect(null, mapDispatch)(TasksInformation);