import { Dialog, List, Divider, Box, Segment, Text, Button, AddIcon, Dropdown, Input } from "@fluentui/react-northstar";
import { useState } from "react";

import { connect, RootStateOrAny, useSelector } from "react-redux";
import { createTopic, getTopics } from "../store/reducers/topicsSlice";

import { headersDashboard } from "../utils/constants";



function TopicsInformation(props: any) {
    let meetingId = props.meeting.id;
    let participants = props.meeting.participants;

    const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    let topics = useSelector((state: RootStateOrAny) => state.topics?.data);
    topics = topics.filter((item: any) => item.meeting_id === meetingId);

    const initialTopic = {
        title: "",
        description: "",
        duration: 0,
        meeting_id: meetingId,
        responsible: [Object.keys(participants)[0]],
    };

    const [
        { title, description, duration, responsible },
        setState
    ] = useState(initialTopic);

    const onChange = (e: any) => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const setTopic = () => {
        let payload = {
            title: title,
            description: description,
            duration: duration,
            meeting_id: meetingId,
            responsible: responsible,
        }
        props.createNewTopic(token, payload);
        setTimeout(() => props.updateData(token, userID), 2000);
    }

    const clearState = () => {
        setState({ ...initialTopic });
    };

    return (
        <>
            <List items={headersDashboard.topics} className="list" />
            <List>
                {
                    topics?.map((topic: any, index: any) =>
                        <Dialog
                            key={index}
                            cancelButton="Cancel"
                            header="Topic informations"
                            content={
                                <>
                                    <Divider color="brand" />
                                    <Box className="boxTopic" >
                                        <Box className="boxGroup">
                                            <Text content={"Title"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.title} />

                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Duration"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.duration + " min"} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Description"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={topic.description} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Conclusion"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222', minHeight: "60px" }} className="inputMeeting" content={topic.conclusion} />
                                        </Box>
                                    </Box>
                                </>
                            }
                            trigger={<List.Item index={index} header={topic.title} endMedia={topic.duration + " min"} className="listItemChain" />}
                        />)
                }
            </List>
            <Dialog
                cancelButton="Cancel"
                confirmButton="Save"
                onCancel={() => {
                    clearState();
                }}
                onConfirm={() => {
                    setTopic();
                    clearState();
                }}
                header="New topic"
                content={
                    <>
                        <Divider />
                        <Box className="boxMeeting" >
                            <Box className="boxGroup">
                                <Text content={"Topic name"} className="titleMeeting" />
                                <Input name="title" required fluid onChange={onChange} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Duration (min)"} className="titleMeeting" />
                                <Input name="duration" required fluid onChange={onChange} />
                            </Box>
                            <Box className="boxGroup">
                                <Text content={"Description"} className="titleMeeting" />
                                <Input name="description" required fluid onChange={onChange} />
                            </Box>
                            {/*
                        <Box className="boxGroup">
                            <Text content={"Participants"} className="titleMeeting" />
                            <Dropdown
                                //error={isConfirming && Object.keys(participants).length <= 1}
                                multiple
                                fluid
                                search
                                items={
                                    participantsSearched.slice(0, 3).map((item: any) => ({ header: item.displayName, key: item.id }))
                                }
                                onChange={addParticipants}
                                onSearchQueryChange={onChangeParticipants}
                                position="below"
                                placeholder="Start typing a name"
                                noResultsMessage="We couldn't find any matches."
                                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                                toggleIndicator={<></>}
                                loadingMessage="Loading..."
                                loading={participantsStatus === "loading"}
                            />
                        </Box>*/}

                        </Box>
                    </>
                }
                trigger={

                    <Button primary style={{ marginTop: 20 }} icon={<AddIcon />} iconPosition="after" content="New topic" />
                } />
        </>
    );
}

const mapDispatch = (dispatch: any) => {
    return {
        createNewTopic: (token: string, payload: any) => {
            dispatch(createTopic({ token, payload }));
        },

        updateData: (token: string, userID: string) => {
            dispatch(getTopics({ token, userID }));
        },
    }
}
export default connect(null, mapDispatch)(TopicsInformation);