import { Dialog, List, Box, Divider, Segment, Text } from "@fluentui/react-northstar";

import { RootStateOrAny, useSelector } from "react-redux";

import { headersDashboard } from "../utils/constants";


function SurveysChainInformation(props: any) {
    const chainsSurveys: any = useSelector((state: RootStateOrAny) => state.surveys?.surveysByMeetings);
    return (
        <>
            <List items={headersDashboard.tasks} className="list" />
            <List>
                {
                    chainsSurveys?.map((survey: any, index: any) => {

                        return (
                            <Dialog
                                key={index}
                                cancelButton="Close"
                                header="Survey information"
                                content={
                                    <>
                                        <Divider color="brand" />
                                        <Box className="boxTopic" >
                                            <Box className="boxGroup">
                                                <Text content={"Title"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={survey.title} />

                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Description"} className="titleMeeting" />
                                                <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={survey.description} />
                                            </Box>
                                            <Box className="boxGroup">
                                                <Text content={"Options"} className="titleMeeting" />
                                                {
                                                    survey?.vote_options.map((option: any, key: any) =>
                                                        <Segment key={key} disabled style={{ color: '#222' }} className="inputMeeting" content={option.option} />
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </>
                                }
                                trigger={<List.Item index={index} header={survey.title} className="listItemChain" />}
                            />)
                    })
                }
            </List>
        </>
    );
}

export default SurveysChainInformation;

