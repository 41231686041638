/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasks } from "../store/reducers/tasksSlice";
import { getTopics } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";

import sortArray from "../utils/sortArray";

import HeaderBar from "../components/HeaderBar";
import MeetingTable from "../components/MeetingTable";
import { login } from "./LoginPage";


function History(props: any) {
  //Get new token if last has expired
  const dispatch = useDispatch();
  let tokenDate = useSelector((state: RootStateOrAny) => state?.credential?.tokenDate);
  tokenDate = new Date(tokenDate);
  const expires_in = useSelector((state: RootStateOrAny) => state?.credential?.data.expires_in);
  const timeOffset = 300;
  if ((new Date()).getTime() > tokenDate.getTime() + (expires_in - timeOffset) * 1000) {
    login(dispatch);
    console.log("RE LOGIN");
  }

  console.log("HISTORY");
  const [searchText, setSearchText] = useState("");
  const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
  const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);

  function update() {
    props.updateData(token, userID);
  }
  useEffect(() => {
    update();
  }, [])


  const meetings: any = useSelector((state: RootStateOrAny) => state.meetings?.data);
  let historyMeetings = meetings?.filter((item: any) => (new Date(item.startTime) < new Date()) && item.title.toLowerCase().includes(searchText.toLowerCase()));
  historyMeetings = sortArray(historyMeetings, "desc");
  return (
    <>
      <HeaderBar setSearchText={setSearchText} />
      <Box className="mainChain" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.grey[50] } }}>
        <MeetingTable meetings={historyMeetings} isStatus updateData={update} />

      </Box>
    </>
  );
}

const mapDispatch = (dispatch: any) => {
  return {
    updateData: (token: string, userID: string) => {
      dispatch(getMeetings(token));
      dispatch(getTasks({ token, userID }));
      dispatch(getTopics({ token, userID }));
      dispatch(getChains(token));
    },
    logout: () => dispatch({ type: "USER_LOGOUT" }),
  }
}
export default connect(null, mapDispatch)(History);