export function getColor(priority: any) {
    switch (priority) {
        case "Low":
            return "#2E9C2C";
        case "Medium":
            return "#EFA920";
        case "High":
            return "#D52429";
        default:
            return "#000000";
    }
}