import { Box, Dialog, Text, Segment, Divider, List, Button, AddIcon } from "@fluentui/react-northstar";

import { RootStateOrAny, useSelector } from "react-redux";

import { headersDashboard } from "../utils/constants";


function SurveysInformation(props: any) {
    let meetingId = props.meeting.id;
    let surveys = useSelector((state: RootStateOrAny) => state.surveys?.data);
    surveys = surveys?.map((survey: any) => survey.at(0))
    surveys = surveys?.filter((item: any) => item.meeting_id === meetingId);
    return (
        <>
            <List items={headersDashboard.tasks} className="list" />
            <List>
                {
                    surveys?.map((survey: any, index: any) =>
                        <Dialog
                            key={index}
                            cancelButton="Close"
                            header="Survey information"
                            content={
                                <>
                                    <Divider color="brand" />
                                    <Box className="boxTopic" >
                                        <Box className="boxGroup">
                                            <Text content={"Title"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={survey.title} />

                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Description"} className="titleMeeting" />
                                            <Segment disabled style={{ color: '#222' }} className="inputMeeting" content={survey.description} />
                                        </Box>
                                        <Box className="boxGroup">
                                            <Text content={"Options"} className="titleMeeting" />
                                            {
                                                survey?.vote_options?.map((option: any, key: any) =>
                                                    <Segment key={key} disabled style={{ color: '#222', marginBottom: 10 }} className="inputMeeting" content={option.option} />
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </>
                            }
                            trigger={<List.Item index={index} header={survey.title} className="listItemChain" />}
                        />
                    )
                }
            </List>
            <Button primary style={{ marginTop: 20 }} icon={<AddIcon />} iconPosition="after" content="New survey" />
        </>
    );
}

export default SurveysInformation;