import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'


export const getTopics = createAsyncThunk("topics/getTopics", async (data: any) => {
    const { token, userID } = data;
    return fetch(baseAPIurl + "topics/users/" + userID, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json())
})

export const getTopicsByMeetingsId = createAsyncThunk("topics/getTopicsByMeetingsId", async (data: any) => {
    const { token, meetingsId } = data;
    return fetch(baseAPIurl + "topics/meetings", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(meetingsId)
    }).then((res) => res.json())
})

export const createTopic = createAsyncThunk("topics/createTopic", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "topics", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})


const topicsSlice = createSlice({
    name: 'topics',
    initialState: {
        data: [],
        topicsByMeetings: [],
        status: null,
        newTopicStatus: null,
    },
    reducers: {

    },
    extraReducers: {
        [getTopics.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getTopics.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getTopics.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },

        [getTopicsByMeetingsId.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getTopicsByMeetingsId.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.topicsByMeetings = action.payload
        },
        [getTopicsByMeetingsId.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
            state.topicsByMeetings = [];
        },


        [createTopic.pending as any]: (state: any, action: any) => {
            state.newTopicStatus = "loading"
        },
        [createTopic.fulfilled as any]: (state: any, action: any) => {
            state.newTopicStatus = "success";
        },
        [createTopic.rejected as any]: (state: any, action: any) => {
            state.newTopicStatus = "failed"
        },
    }
})

const topicsReducer = topicsSlice.reducer;
export default topicsReducer;