import { List, Skeleton } from "@fluentui/react-northstar";
import _ from 'lodash'

import { useSelector } from "react-redux";


export default function SurveysList() {
    const decisions = useSelector((state) => state.surveys.data);
    const status = useSelector((state) => state.surveys.status);
    return (
        <List>
            {(status !== "success") ?
                _.times(3, index => (
                    <List.Item key={index} header={<Skeleton animation="pulse"><Skeleton.Line width="120px" /></Skeleton>} />
                ))
                :
                (decisions?.length >= 0) ? decisions.slice(0, 5).map(
                    (item, key) => (
                        <List.Item key={key} header={item?.at(0).title} onClick={() => { }} className="listItem" />
                    )
                ) : ""}
        </List>
    )
} 