import { useState, useEffect } from "react";

import { Box, Checkbox, Avatar, Button, Dialog, Text, Loader } from "@fluentui/react-northstar";
import { CalendarIcon, ShiftActivityIcon, AttendeeIcon, TrashCanIcon, LocationIcon, PollIcon } from '@fluentui/react-icons-northstar'

import { useSelector, useDispatch } from "react-redux";

import { getParticipantsById } from "../store/reducers/participantsSlice"

import { getIcon } from "../utils/getIcon";
import { statusItem } from "../utils/constants";


export function HorizontalMeetingHeader(props) {
    const boxProps = { ...props };
    delete boxProps.isStatus;
    delete boxProps.setDeletedList;
    delete boxProps.deletedList;
    delete boxProps.clearState;
    delete boxProps.deleteAll;

    const [toogle, setToggle] = useState(true);
    const [open, setOpen] = useState(false);

    const deletedStatus = useSelector(state => state.meetings.deleteMeetingStatus);
    let deletedNumber = 0;

    Object.keys(props.deletedList)?.map((meetingID) => {
        if (props.deletedList[meetingID]) {
            deletedNumber++;
        }
        return (null);
    })
    return (
        <Box className="meetingContainer" styles={{ backgroundColor: "#fff" }}>
            <Box className="meetingCheckboxHeader">
                <Checkbox onChange={() => {
                    props.clearState(toogle);
                    setToggle(!toogle);
                }} />
            </Box>
            <Box {...boxProps} className="meetingItemHeader" >

                <Box className="nameItem">
                    {props.name}
                </Box>
                <Box className="nameItem">
                    <CalendarIcon outline size="large" className="iconItem" />
                    {props.date}
                </Box>
                <Box className="nameItem">
                    <ShiftActivityIcon outline size="large" className="iconItem" />
                    {props.time}
                </Box>
                <Box className="nameItem">
                    <LocationIcon outline size="large" className="iconItem" />
                    {props.location}
                </Box>
                {props.isStatus ? <Box className="nameItem">
                    <PollIcon outline size="large" className="iconItem" />
                    {props.status}
                </Box> : ""}
                <Box className="nameItem">
                    <AttendeeIcon outline size="large" className="iconItem" />
                    {props.participants}
                </Box>
                <Box className="nameItemTrashHeader">
                    <Dialog
                        cancelButton="Cancel"
                        open={open}
                        onOpen={() => setOpen(true)}
                        onCancel={() => {
                            if (deletedStatus !== "loading") {
                                setOpen(false);
                            }
                        }}
                        onConfirm={() => {
                            props.deleteAll();
                            setTimeout(() => setOpen(false), 500);

                        }}
                        confirmButton="Confirm"
                        content={<>
                            <Text>Are you sure you want to delete {deletedNumber > 1 ? "these meetings ?" : "this meeting ?"}</Text>
                            {deletedStatus === "loading" ? <Loader style={{ marginTop: 50, marginBottom: 50 }} size="small" /> : ""}
                        </>
                        }
                        header="Delete confirmation"
                        trigger={<Button text icon={<TrashCanIcon outline size="large" />} iconOnly title="Delete" />}

                    />

                </Box>
            </Box>
        </Box>
    );
}

function HorizontalMeeting(props) {
    const boxProps = { ...props };
    delete boxProps.isStatus;
    delete boxProps.setDeletedList;
    delete boxProps.deletedList;
    const dispatch = useDispatch();
    const token = "Bearer " + useSelector(state => state.credential.data.id_token);

    useEffect(() => {
        for (let j = 0; j < Object.keys(props.participants).length; j++) {
            let participantId = Object.keys(props.participants)[j];
            dispatch(getParticipantsById({ token: token, userId: participantId }));
        }

    }, [token, dispatch, props.participants])

    let participantsList = useSelector(state => state.participants.participantsList);
    let participants = {};
    for (let i = 0; i < Object.keys(participantsList).length; i++) {
        let participandId = Object.keys(participantsList)[i];
        if ((participandId in props.participants)) {
            participants[participandId] = participantsList[participandId];
        }
    }

    return (
        <Box className="meetingContainer" styles={{ backgroundColor: (props.index + 1) % 2 === 0 ? '#f2f2f2' : '#fafafa' }} >
            <Box className="meetingCheckbox">
                <Checkbox checked={props.deletedList[props.id]} onChange={() => props.setDeletedList((prevState) => ({ ...prevState, [props.id]: !props.deletedList[props.id] }))} />
            </Box>
            <Box {...boxProps} className="meetingItem" >
                <Box className="nameItem">
                    {props.name}
                </Box>
                <Box className="nameItem">
                    {props.date}
                </Box>
                <Box className="nameItem">
                    {props.time}
                </Box>
                <Box className="nameItem">
                    {props.location}
                </Box>
                {props.isStatus ? <Box className="nameItem">
                    {getIcon(props.status)}
                    {statusItem[props.status]}
                </Box> : ""}
                <Box className="nameItem">
                    {Object.keys(participants)?.map((participant, index) => {
                        let displayName = participants[participant].displayName;
                        return (
                            <Avatar key={index} name={displayName} />
                        )
                    }
                    )}

                </Box>
                <Box className="nameItemTrash">

                </Box>
            </Box>
        </Box>
    );
}

export default HorizontalMeeting;