import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'

export const getChains = createAsyncThunk("meetings/getChains", async (token: string) => {
    //let token = "Bearer " + useSelector(state => state?.credential?.data?.id_token);
    return fetch(baseAPIurl + "chains", {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json())
})

const chainsSlice = createSlice({
    name: 'chains',
    initialState: {
        data: [],
        status: null,
    },
    reducers: {

    },
    extraReducers: {
        [getChains.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getChains.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getChains.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },
    }
})

const chainsReducer = chainsSlice.reducer;
export default chainsReducer;