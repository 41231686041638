export default function sortArray(array, sens) {
    switch (sens) {
        case "asc":
            return array?.sort(
                (objA, objB) => Number(new Date(objA.startTime)) - Number(new Date(objB.startTime)),
            );
        case "desc":
            return array.sort(
                (objA, objB) => Number(new Date(objB.startTime)) - Number(new Date(objA.startTime)),
            );
        default:
            return array.sort(
                (objA, objB) => Number(new Date(objA.startTime)) - Number(new Date(objB.startTime)),
            );
    }

}