import { useState } from "react";
import { Box, Dialog, Datepicker, Button, Divider, Text, Input, Dropdown, AddIcon } from "@fluentui/react-northstar";

import { RootStateOrAny, connect, useSelector } from "react-redux";

import formatDate from "../utils/formatDate";

import { createMeeting } from "../store/reducers/meetingsSlice";
import { getParticipants } from "../store/reducers/participantsSlice";
import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasks } from "../store/reducers/tasksSlice";
import { getTopics } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";
import { getSurveys } from "../store/reducers/surveysSlice";


const initialStateMeeting = {
    title: "",
    description: "",
    location: "",
    startTime: new Date(),
    endTime: new Date(),
    meetingStartTime: formatDate(new Date(), "HOUR1M"),
    meetingEndTime: formatDate(new Date(), "HOUR1H"),
    status: "0",
    participants: {},
    tasks: [],
    decisions: [],
    report: [],
    emailmsg: {},
};

function NewMeeting(props: any) {
    let participantsSearched = useSelector((state: RootStateOrAny) => state.participants.data);
    let participantsStatus = useSelector((state: RootStateOrAny) => state.participants.status);

    const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    const meetings = useSelector((state: RootStateOrAny) => state.meetings.data);

    const [open, setOpen] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);

    const [
        { title, description, location, startTime, endTime, meetingStartTime, meetingEndTime, status, participants, tasks, decisions, report, emailmsg },
        setState
    ] = useState(initialStateMeeting);

    startTime.setHours(parseInt(meetingStartTime.split(":")[0]), parseInt(meetingStartTime.split(":")[1]), 0);
    endTime.setHours(parseInt(meetingEndTime.split(":")[0]), parseInt(meetingEndTime.split(":")[1]), 0);

    const clearState = () => {
        setState({ ...initialStateMeeting });
    };


    const onChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "meetingStartTime") {
            startTime.setHours(parseInt(value.split(":")[0]), parseInt(value.split(":")[1]), 0);
        }
        if (name === "meetingEndTime") {
            endTime.setHours(parseInt(value.split(":")[0]), parseInt(value.split(":")[1]), 0);
        }
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onChangeParticipants = (e: any, data: any) => {
        let search = data.searchQuery;
        props.getParticipants(token, search);
    };

    const addParticipants = (e: any, data: any) => {
        let listParticipants: any = {};
        let values = data.value;
        for (let i = 0; i < values.length; i++) {
            listParticipants[values[i].key] = 2;
        }
        listParticipants[userID] = 0;
        setState(prevState => ({ ...prevState, participants: listParticipants }));
    };


    const setMeeting = () => {
        setIsConfirming(true);
        setState(prevState => ({ ...prevState, participants: { userID: 0 } }));
        const payL = {
            title: title,
            description: description,
            location: location,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            status: status,
            participants: { [userID]: 0 },
            tasks: tasks,
            decisions: decisions,
            report: report,
            emailmsg: emailmsg,
        };

        if (title && description && location && startTime !== new Date() && endTime !== new Date() && status && participants && startTime.getTime() >= new Date().getTime() && startTime.getTime() < endTime.getTime()) {
            props.createMeeting(token, payL);
            setOpen(false);
            clearState();
            setIsConfirming(false);
            setTimeout(() => props.updateData(token, userID, meetings), 2000);
        }
    }


    return (
        <Dialog
            cancelButton="Cancel"
            confirmButton="Save"
            open={open}
            onOpen={() => {
                setState(prevState => ({ ...prevState, meetingStartTime: formatDate(new Date(), "HOUR1M") }));
                setState(prevState => ({ ...prevState, meetingEndTime: formatDate(new Date(), "HOUR1H") }));
                setOpen(true);
            }}
            onCancel={() => {
                setIsConfirming(false);
                clearState();
                setOpen(false);
            }}
            onConfirm={() => {
                setMeeting();
            }}
            header="Meeting informations"
            content={
                <>
                    <Divider />
                    <Box className="boxMeeting" >
                        <Box className="boxGroup">
                            <Text content={"Meeting name"} className="titleMeeting" />
                            <Input name="title" required fluid onChange={onChange} error={isConfirming && !title} />
                        </Box>
                        <Box className="boxGroup">
                            <Text content={"Location"} className="titleMeeting" />
                            <Input name="location" required fluid onChange={onChange} error={isConfirming && !location} />
                        </Box>
                        <Box className="boxGroup">
                            <Box className="boxDouble">
                                <Box className="smallBox">
                                    <Text content={"When"} className="titleMeeting" />
                                    <Box>
                                        <Datepicker defaultSelectedDate={new Date()} minDate={new Date()} today={new Date()} allowManualInput={false} className="datePicker" onDateChange={(e, value: any) => {
                                            setState(prevState => ({ ...prevState, startTime: new Date(value.itemProps.value.key) }));
                                            setState(prevState => ({ ...prevState, endTime: new Date(value.itemProps.value.key) }));
                                        }} />
                                    </Box>

                                </Box>
                                <Box className="smallBoxTime">
                                    <Text content={"From"} className="titleMeeting" />
                                    <input value={meetingStartTime} className={isConfirming && startTime.getTime() < new Date().getTime() ? "timePickerError" : "timePicker"} type="time" name="meetingStartTime" onChange={onChange} />
                                </Box>
                                <Box className="smallBoxTime">
                                    <Text content={"To"} className="titleMeeting" />
                                    <input value={meetingEndTime} className={isConfirming && startTime.getTime() >= endTime.getTime() ? "timePickerError" : "timePicker"} type="time" name="meetingEndTime" onChange={onChange} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className="boxGroup">
                            <Text content={"Participants"} className="titleMeeting" />
                            <Dropdown
                                //error={isConfirming && Object.keys(participants).length <= 1}
                                multiple
                                fluid
                                search
                                items={
                                    participantsSearched.slice(0, 3).map((item: any) => ({ header: item.displayName, key: item.id }))
                                }
                                onChange={addParticipants}
                                onSearchQueryChange={onChangeParticipants}
                                position="below"
                                placeholder="Start typing a name"
                                noResultsMessage="We couldn't find any matches."
                                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                                toggleIndicator={<></>}
                                loadingMessage="Loading..."
                                loading={participantsStatus === "loading"}
                            />
                        </Box>
                        <Box className="boxGroup">
                            <Text content={"Description"} className="titleMeeting" />
                            <Input name="description" required fluid onChange={onChange} error={isConfirming && !description} />
                        </Box>
                    </Box>
                </>
            }
            trigger={

                <Button primary className="newMeeting" icon={<AddIcon />} iconPosition="before" content="New meeting" />
            } />
    );
}

const mapDispatch = (dispatch: any) => {
    return {
        createMeeting: (token: string, payload: any) => {
            dispatch(createMeeting({ token, payload }));
        },
        getParticipants: (token: string, payload: any) => {
            dispatch(getParticipants({ token, payload }));
        },
        updateData: (token: string, userID: string, meetings: any) => {
            dispatch(getMeetings(token));
            dispatch(getTasks({ token, userID }));
            dispatch(getTopics({ token, userID }));
            dispatch(getChains(token));
            dispatch(getSurveys({ token, meetings }))
        },
        logout: () => dispatch({ type: "USER_LOGOUT" }),
    }
}
export default connect(null, mapDispatch)(NewMeeting);