import { useState, useEffect } from "react";
import { Box, Input, Flex, Button, Avatar, Skeleton } from "@fluentui/react-northstar";
import { SearchIcon } from '@fluentui/react-icons-northstar'

import { useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";

import getWindowDimensions from "../utils/getWindowDimensions";

import NewMeeting from "./NewMeeting";


function HeaderBar(props) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const given_name = useSelector((state) => state.user.data?.given_name);
    const family_name = useSelector((state) => state.user.data?.family_name);
    let status = "loading";
    status = useSelector((state) => state.user.status);
    let name;
    if (given_name && family_name) {
        name = given_name + " " + family_name;
    } else {
        name = "";
    }
    const dispatch = useDispatch();
    const logout = () => {
        dispatch({ type: "USER_LOGOUT" })
        storage.removeItem('persist:root')
    }
    return (
        <>
            <Box className="headerBar" styles={theme => { return { backgroundColor: theme.theme.siteVariables.colors.white }; }}>
                <Avatar name={name} />
                <Box style={{ width: '20%', alignItems: 'center', display: 'flex', marginLeft: 10 }} >
                    {status === "loading" ?
                        <Skeleton animation="pulse" className="meetingContainer" style={{ alignItems: 'center', display: 'flex' }}>
                            <Skeleton.Line width='80%' />
                        </Skeleton>
                        : name}

                </Box>

                <Flex
                    styles={{ width: '100%', gridColumn: '1 / span 2', justifyContent: 'flex-end' }}
                    wrap
                    space="between"
                >

                    <Input hidden={windowDimensions.width < 750} placeholder="Find" icon={<SearchIcon />} onChange={(e) => props.setSearchText(e.target.value)} autoComplete="false" />
                    <NewMeeting />
                    <Button onClick={logout} style={{ marginLeft: 20 }}>Logout</Button>
                </Flex>
            </Box>
        </>
    );
}

export default HeaderBar;