/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, Divider, Menu, PaperclipIcon, Skeleton, tabListBehavior } from "@fluentui/react-northstar";
import _ from 'lodash'

import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { getTasksByMeetingsId } from "../store/reducers/tasksSlice";
import { deleteMeeting } from "../store/reducers/meetingsSlice";

import formatDate from "../utils/formatDate";
import { headerMeetingtable } from "../utils/constants";
import getWindowDimensions from "../utils/getWindowDimensions";
import { menuChainItems } from "../utils/constants";

import HorizontalMeeting, { HorizontalMeetingHeader } from "../components/HorizontalMeeting";
import MeetingInformation from "../components/MeetingInformation";
import TasksChainInformation from "./TasksChainInformation";
import SurveysChainInformation from "./SurveysChainInformation";
import TopicsChainInformation from "./TopicsChainInformation";


function SwitchTab(props: any) {
    switch (props.activeMenu) {
        case "tasks":
            return (<TasksChainInformation />)
        case "surveys":
            return (<SurveysChainInformation />)
        case "topics":
            return (<TopicsChainInformation />)
        default:
            return (<Box>DEFAULT</Box>)
    }
}


function MeetingTable(props: any) {
    const windowDimensions = getWindowDimensions();
    let rowNumber = Math.floor((windowDimensions.height - 100) / 50);
    const dispatch = useDispatch();

    let chainName = props.chainName;
    const meetings: any = props.meetings;
    let status = "loading";

    const [deletedList, setDeletedList] = useState<any>({});
    const [activeMenu, setActiveMenu] = useState("topics");

    status = useSelector((state: RootStateOrAny) => state.meetings.status);
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);


    function clearState(toggle: any) {
        for (let i = 0; i < meetings.length; i++) {
            let meeting = meetings.at(i);
            setDeletedList((prevState: any) => ({ ...prevState, [meeting.id]: toggle }));
        }
    }
    function deleteAll() {
        Object.keys(deletedList)?.map((meetingID: any) => {
            if (deletedList[meetingID]) {
                dispatch(deleteMeeting({ token, meetingID }));
            }
            return (null);
        })
        setTimeout(() => props.updateData(), 1000);
    }

    useEffect(() => {
        clearState(false);
    }, [])
    return (
        <Box className={props.isStatus ? "tableContainerUpcoming" : "tableContainer"}>
            {props.isChain ? <Box className="buttonOpenContainer">
                <Dialog
                    cancelButton="Close"
                    header={chainName}
                    onCancel={() => {
                        setActiveMenu("topics")
                    }}
                    content={
                        <>
                            <Divider color="brand" />
                            <Menu className="informationMenu" style={{ border: 'none' }} defaultActiveIndex={0} accessibility={tabListBehavior} underlined primary >
                                {menuChainItems?.map((item: any, index: any) => {
                                    return (
                                        <Menu.Item index={index} key={index} onClick={() => setActiveMenu(item.key)} style={{ borderWidth: activeMenu === item.key ? 3 : 0, paddingBottom: activeMenu === item.key ? 5 : 8 }} >
                                            <Menu.ItemContent className="informationMenuItem">{item.content}</Menu.ItemContent>
                                        </Menu.Item>
                                    )
                                }
                                )}
                            </Menu>
                            <Box className="informationBox">
                                {<SwitchTab activeMenu={activeMenu} />}
                            </Box>
                        </>
                    }
                    trigger={<Button icon={<PaperclipIcon outline size="large" />} primary content="Open Chain" iconPosition="after" className="chainButton" />} />

            </Box> : ""}
            <Box className="rowList">
                {headerMeetingtable ?
                    <HorizontalMeetingHeader
                        name={headerMeetingtable.name}
                        date={headerMeetingtable.date}
                        time={headerMeetingtable.time}
                        participants={headerMeetingtable.participants}
                        location={headerMeetingtable.location}
                        status={headerMeetingtable.status}
                        isStatus={props.isStatus}
                        setDeletedList={setDeletedList}
                        deletedList={deletedList}
                        clearState={clearState}
                        deleteAll={deleteAll}
                    />
                    : ""}

                {(status !== "success") ?
                    _.times(rowNumber, index => (
                        <Skeleton key={index} styles={{ backgroundColor: index % 2 === 0 ? '#f5f2f2' : 'transparent' }} animation="pulse" className="meetingContainer" >
                            <Box className="meetingCheckbox">
                                <Checkbox />
                            </Box>
                            <Box className="meetingItem" >
                                <Box className="nameItem">
                                    <Skeleton.Line width='50%' />
                                </Box>
                                <Box className="nameItem">
                                    <Skeleton.Line width='50%' />
                                </Box>
                                <Box className="nameItem">
                                    <Skeleton.Line width='50%' />
                                </Box>
                                <Box className="nameItem">
                                    <Skeleton.Line width='50%' />
                                </Box>
                                {props.isStatus ?
                                    <Box className="nameItem">
                                        <Skeleton.Line width='50%' />
                                    </Box> : ""}
                                <Box className="nameItem">
                                    <Skeleton.Shape round width="32px" height="32px" />
                                </Box>
                                <Box className="nameItemTrash">
                                </Box>
                            </Box>
                        </Skeleton>
                    ))
                    :
                    meetings?.map(
                        (meeting: any, index: any) => {
                            return (
                                <MeetingInformation key={index} trigger={
                                    <HorizontalMeeting
                                        key={index}
                                        index={index}
                                        name={meeting.title}
                                        date={formatDate(new Date(meeting.startTime), "SHORT")}
                                        time={formatDate(new Date(meeting.startTime), "HOUR") + " - " + formatDate(new Date(meeting.endTime), "HOUR")}
                                        participants={meeting.participants}
                                        location={meeting.location}
                                        status={meeting.status}
                                        isStatus={props.isStatus}
                                        id={meeting.id}
                                        setDeletedList={setDeletedList}
                                        deletedList={deletedList}
                                    />}
                                    meeting={meeting}
                                />

                            );
                        }
                    )}
            </Box>
        </Box>
    )
};


const mapDispatch = (dispatch: any) => {
    return {
        updateTasks: (token: string, meetingsId: any) => {
            dispatch(getTasksByMeetingsId({ token, meetingsId }));
        },
    }
}
export default connect(null, mapDispatch)(MeetingTable);