function padTo2Digits(num: Number) {
    return num.toString().padStart(2, '0');
}

export default function formatDate(date: Date, length: String) {
    switch (length) {
        case "SHORT":
            return (
                [
                    padTo2Digits(date.getDate()),
                    padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join('/') +
                ' '
            );
        case "LONG":
            return (
                [
                    padTo2Digits(date.getDate()),
                    padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join('/') +
                ' ' +
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                ].join(':')
            );
        case "HOUR":
            return (
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                ].join(':')
            );
        case "HOUR1M":
            date.setMinutes(date.getMinutes() + 1);
            return (
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                ].join(':')
            );
        case "HOUR1H":
            date.setMinutes(date.getMinutes() + 1);
            date.setHours(date.getHours() + 1);
            return (
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                ].join(':')
            );
        case "SHORTNAME":
            let day = date.toLocaleDateString('fr-FR', { weekday: "short" });
            day = day.charAt(0).toUpperCase() + day.slice(1);
            let month = date.toLocaleDateString('fr-FR', { month: "long" });
            let year = date.toLocaleDateString('fr-FR', { year: "numeric" });
            return (
                day + " " + month + " " + year.toString()
            );
        default:
            return (
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                ].join(':')
            );
    }
}
