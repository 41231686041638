import { Box, Header, Skeleton } from "@fluentui/react-northstar";

import { RootStateOrAny, useSelector } from "react-redux";

import getWindowDimensions from "../utils/getWindowDimensions";

import TaskCard from "../components/TaskCard";



export default function TaskColumn(props: any) {
    const key = props.index;
    const taskColumn = props.taskColumn;
    const selectedCol = props.selectedCol;
    const isSelecting = props.isSelecting;
    const taskIndex = props.taskIndex;
    const setIsSelecting = props.setIsSelecting;
    const setSelectedCol = props.setSelectedCol;
    const setSelectedTask = props.setSelectedTask;
    const setTaskIndex = props.setTaskIndex;

    const searchText = props.searchText;
    const updateStatus = props.updateStatus;


    const windowDimensions = getWindowDimensions();
    let windowHeight = windowDimensions.height;

    let tasks = useSelector((state: RootStateOrAny) => state.tasks?.data);
    tasks = tasks?.filter((item: any) => item.status === taskColumn.title && item.title.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <Box key={key} className="taskColumn" style={{ borderRight: taskColumn.key !== "onhold" ? "solid 1px #ccc" : "" }}>
            <Header as="h2">{taskColumn.title}</Header>
            {isSelecting && key !== selectedCol ?
                <Skeleton.Shape className="taskCardHere" width='90%' style={{ margin: 20, height: windowHeight, display: "flex" }} onClick={() => {
                    setIsSelecting(!isSelecting);
                    setSelectedCol(key);
                    updateStatus(key);
                }}>Move task here</Skeleton.Shape>
                :
                tasks?.map((task: any, index: any) =>
                    <TaskCard key={index} task={task} onClick={() => {
                        setIsSelecting(!isSelecting);
                        setSelectedCol(key);
                        setSelectedTask(task);
                        setTaskIndex(index);
                    }} style={{
                        backgroundColor: taskIndex === index && selectedCol === key ? "#E2E5FF" : "",
                        transform: taskIndex === index && selectedCol === key ? "translateY(-3px)" : "",
                    }} />
                )
            }
        </Box>
    );
}
