import { Box, Text, Segment } from "@fluentui/react-northstar";
import { RootStateOrAny, useSelector } from "react-redux";


function ReportInformation(props: any) {
    let meetingId = props.meeting.id;
    let topics = useSelector((state: RootStateOrAny) => state.topics?.data);
    topics = topics.filter((item: any) => item.meeting_id === meetingId);
    console.log(topics)
    return (
        <>
            {
                topics?.map((topic: any, index: any) =>
                    <Box className="boxGroup" key={index}>
                        <Text content={topic.title} className="titleMeeting" />
                        <Segment disabled style={{ color: '#222', minHeight: topic.conclusion ? "60px" : "" }} className="inputMeeting" content={topic.conclusion ? topic.conclusion : "No conclusion."} />
                    </Box>
                )
            }
        </>
    );
}

export default ReportInformation;