import { useState } from "react";
import { Box, Dialog, Divider, Flex, FlexItem, Button, Menu, tabListBehavior, Loader, Dropdown } from "@fluentui/react-northstar";

import { connect, RootStateOrAny, useSelector } from "react-redux";

import formatDate from "../utils/formatDate";
import { menuItems, statusItem } from "../utils/constants";

import { updateMeeting } from "../store/reducers/meetingsSlice";
import { getParticipants } from "../store/reducers/participantsSlice";
import { getMeetings } from "../store/reducers/meetingsSlice";
import { getTasks } from "../store/reducers/tasksSlice";
import { getTopics } from "../store/reducers/topicsSlice";
import { getChains } from "../store/reducers/chainsSlice";
import { getSurveys } from "../store/reducers/surveysSlice";

import GeneralInformation from "./GeneralInformation";

import "../style/MeetingCard.css";
import TopicsInformation from "./TopicsInformation";
import TasksInformation from "./TasksInformation";
import SurveysInformation from "./SurveysInformation";
import ReportInformation from "./ReportInformation";
import { getIcon } from "../utils/getIcon";


function SwitchTab(props: any) {
    if (props.activeMenu !== "general") {
        props.setEdit(false);
    }
    switch (props.activeMenu) {
        case "general":
            return (<GeneralInformation edit={props.edit} meeting={props.meeting} setState={props.setState} meetingData={props.meetingData} isConfirming={props.isConfirming} getParticipants={props.getParticipants} />)
        case "topics":
            return (<TopicsInformation meeting={props.meeting} />)
        case "tasks":
            return (<TasksInformation meeting={props.meeting} />)
        case "surveys":
            return (<SurveysInformation meeting={props.meeting} />)
        case "report":
            return (<ReportInformation meeting={props.meeting} />)
        default:
            return (<Box>DEFAULT</Box>)
    }
}


function MeetingInformation(props: any) {
    const meeting = props.meeting;

    const [edit, setEdit] = useState(false)
    const [open, setOpen] = useState(false);
    const [changedStatus, setChangedStatus] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [activeMenu, setActiveMenu] = useState("general");

    const userID = useSelector((state: RootStateOrAny) => state.user.data?.username)?.slice(4,);
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    const meetings = useSelector((state: RootStateOrAny) => state.meetings.data);

    const updateStatus = useSelector((state: RootStateOrAny) => state.meetings.updateMeetingStatus);
    const initialStateMeeting = {
        title: meeting?.title || "",
        description: meeting?.description || "",
        location: meeting?.location || "",
        startTime: new Date(meeting?.startTime),
        endTime: new Date(meeting?.startTime),
        status: meeting?.status || "0",
        meetingStartTime: formatDate(new Date(meeting.startTime), "HOUR") || "00:00",
        meetingEndTime: formatDate(new Date(meeting.endTime), "HOUR") || "00:00",
        participants: meeting?.participants,
        tasks: [],
        decisions: [],
        report: [],
        emailmsg: {},
        chain_id: meeting.chain_id || null,
        id: meeting.id,
        serialType: meeting.serialType || null,
    };

    const [
        { title, description, location, startTime, endTime, status, meetingStartTime, meetingEndTime, participants, tasks, decisions, report, emailmsg, chain_id, id, serialType },
        setState
    ] = useState(initialStateMeeting);
    let meetingData = {
        title: title,
        description: description,
        location: location,
        startTime: startTime,
        endTime: endTime,
        status: status,
        participants: participants,
        meetingStartTime: meetingStartTime,
        meetingEndTime: meetingEndTime,

    }

    const clearState = () => {
        setState({ ...initialStateMeeting });
    };

    const setStatus = (e: any, data: any) => {
        let value = data.value;
        if (status !== value) {
            value = Object.keys(statusItem).find(key => statusItem[key] === value);
            setState((prevState: any) => ({ ...prevState, status: value }));
            const newMeeting = { ...meeting, status: value };
            props.updateMeeting(token, newMeeting, newMeeting.id);
            setChangedStatus(true);
        } else {
            setChangedStatus(false);
        }

    };

    const setMeeting = () => {
        //setState(prevState => ({ ...prevState, participants: { [userID]: 0 } }));
        setIsConfirming(true);
        if (meetingStartTime) {
            startTime.setHours(parseInt(meetingStartTime.split(":")[0]), parseInt(meetingStartTime.split(":")[1]), 0);
        }
        if (meetingEndTime) {
            endTime.setHours(parseInt(meetingEndTime.split(":")[0]), parseInt(meetingEndTime.split(":")[1]), 0);
        }

        const payL = {
            title: title,
            description: description,
            location: location,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            status: status,
            participants: participants,
            tasks: tasks,
            decisions: decisions,
            report: report,
            emailmsg: emailmsg,
            id: id,
            chain_id: chain_id,
            serialType: serialType,
        };

        if (title && description && location && startTime !== new Date() && endTime !== new Date() && status && participants && startTime < endTime) {
            props.updateMeeting(token, payL, id);
            setIsConfirming(false);
            if (updateStatus === "success") {
                setTimeout(() => setOpen(false), 500);
            }
            clearState();
            setTimeout(() => props.updateData(token, userID, meetings), 1000);
        }
    }

    return (
        <Dialog
            confirmButton={edit ? "Save" : "Close"}
            header="Meeting informations"
            open={open}
            onOpen={() => setOpen(true)}
            onCancel={() => {
                if (!edit && updateStatus !== "loading") {
                    setIsConfirming(false);
                    setActiveMenu("general");
                    clearState();
                    setOpen(false);
                    if (changedStatus) {
                        setTimeout(() => props.updateData(token, userID, meetings), 400);
                    }
                }
            }}
            onConfirm={() => {
                if (!edit && updateStatus !== "loading") {
                    setTimeout(() => props.updateData(token, userID, meetings), 1000);
                    clearState();
                    setOpen(false);
                } else {
                    setMeeting();
                }

            }}
            footer={{
                children: (Component, props) => {
                    const { styles, ...rest } = props
                    return (
                        <Flex styles={{ ...styles, width: '100%', gridColumn: '1 / span 2', justifyContent: 'space-between' }} wrap space="between">

                            <Flex>

                                <Dropdown
                                    fluid
                                    value={statusItem[status]}
                                    items={Object.keys(statusItem).map((item, index) => statusItem[item])}
                                    placeholder="Status..."
                                    checkable
                                    styles={{ marginLeft: '0px', marginRight: '0px', cursor: "pointer" }}
                                    onChange={setStatus}
                                />
                                <Box className="statusIcon">
                                    {getIcon(status)}
                                </Box>
                            </Flex>
                            {updateStatus === "loading" ? <Loader size="small" /> : ""}
                            <Flex>
                                {
                                    activeMenu === "general" ?
                                        <Button content={edit ? "Cancel" : "Edit"} styles={{ marginLeft: '50px', marginRight: '20px', [`@media screen and (max-width: 400px )`]: { width: '100%', maxWidth: '100%', }, }} onClick={() => { setEdit(!edit); clearState(); }} />
                                        : ""
                                }

                                <FlexItem styles={{ marginLeft: 0, [`@media screen and (max-width: 400px)`]: { width: '100%', }, }}>
                                    <Component {...rest} />
                                </FlexItem>
                            </Flex>
                        </Flex>
                    )
                },
            }}
            content={
                <>
                    <Divider color="brand" />
                    <Menu className="informationMenu" style={{ border: 'none' }} defaultActiveIndex={0} accessibility={tabListBehavior} underlined primary >
                        {menuItems?.map((item: any, index: any) => {
                            return (
                                <Menu.Item index={index} key={index} onClick={() => setActiveMenu(item.key)} style={{ borderWidth: activeMenu === item.key ? 3 : 0, paddingBottom: activeMenu === item.key ? 5 : 8 }} >
                                    <Menu.ItemContent className="informationMenuItem">{item.content}</Menu.ItemContent>
                                </Menu.Item>
                            )
                        }
                        )}
                    </Menu>
                    <Box className="informationBox">
                        {<SwitchTab activeMenu={activeMenu} setEdit={setEdit} edit={edit} meeting={meeting} setState={setState} meetingData={meetingData} isConfirming={isConfirming} getParticipants={props.getParticipants} />}
                    </Box>
                </>
            }
            trigger={props.trigger} />
    );
}

const mapDispatch = (dispatch: any) => {
    return {
        updateMeeting: (token: string, payload: any, meetingID: any) => {
            dispatch(updateMeeting({ token, payload, meetingID }));
        },
        getParticipants: (token: string, payload: any) => {
            dispatch(getParticipants({ token, payload }));
        },
        updateData: (token: string, userID: string, meetings: any) => {
            dispatch(getMeetings(token));
            dispatch(getTasks({ token, userID }));
            dispatch(getTopics({ token, userID }));
            dispatch(getChains(token));
            dispatch(getSurveys({ token, meetings }))
        },
        logout: () => dispatch({ type: "USER_LOGOUT" }),
    }
}
export default connect(null, mapDispatch)(MeetingInformation);