import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseAPIurl } from '../../utils/constants'


export const getMeetings = createAsyncThunk("meetings/getMeetings", async (token: string) => {
    return fetch(baseAPIurl + "meetings", {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json())
})

export const createMeeting = createAsyncThunk("meetings/createMeeting", async (data: any) => {
    const { token, payload } = data;
    return fetch(baseAPIurl + "meetings", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})

export const updateMeeting = createAsyncThunk("meetings/updateMeeting", async (data: any) => {
    const { token, payload, meetingID } = data;
    return fetch(baseAPIurl + "meetings/" + meetingID, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(payload)
    }).then((res) => res.json()).catch(err => console.log(err));
})


export const deleteMeeting = createAsyncThunk("meetings/deleteMeeting", async (data: any) => {
    const { token, meetingID } = data;
    return fetch(baseAPIurl + "meetings/" + meetingID, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }).then((res) => res.json()).catch(err => console.log(err));
})


const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: {
        data: [],
        status: null,
        newMeeting: {},
        newMeetingStatus: null,
        updateMeetingStatus: null,
        deleteMeetingStatus: null,
    },
    reducers: {
        setMeetingData: (state, action) => {
            console.log("NEW MEETING ", action.payload);
            state.newMeeting = action.payload;
        },
    },
    extraReducers: {
        [getMeetings.pending as any]: (state: any, action: any) => {
            state.status = "loading"
        },
        [getMeetings.fulfilled as any]: (state: any, action: any) => {
            state.status = "success"
            state.data = action.payload
        },
        [getMeetings.rejected as any]: (state: any, action: any) => {
            state.status = "failed"
        },


        [createMeeting.pending as any]: (state: any, action: any) => {
            state.newMeetingStatus = "loading"
        },
        [createMeeting.fulfilled as any]: (state: any, action: any) => {
            state.newMeetingStatus = "success";
            state.newMeeting = action.payload
        },
        [createMeeting.rejected as any]: (state: any, action: any) => {
            state.newMeetingStatus = "failed"
        },


        [updateMeeting.pending as any]: (state: any, action: any) => {
            state.updateMeetingStatus = "loading"
        },
        [updateMeeting.fulfilled as any]: (state: any, action: any) => {
            state.updateMeetingStatus = "success";
        },
        [updateMeeting.rejected as any]: (state: any, action: any) => {
            state.updateMeetingStatus = "failed"
        },


        [deleteMeeting.pending as any]: (state: any, action: any) => {
            state.deleteMeetingStatus = "loading"
        },
        [deleteMeeting.fulfilled as any]: (state: any, action: any) => {
            state.deleteMeetingStatus = "success";
        },
        [deleteMeeting.rejected as any]: (state: any, action: any) => {
            state.deleteMeetingStatus = "failed"
        },
    },
})

const meetingsReducer = meetingsSlice.reducer;
export const { setMeetingData } = meetingsSlice.actions;
export default meetingsReducer;