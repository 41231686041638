import { Flex, Skeleton } from "@fluentui/react-northstar";

import { useSelector } from "react-redux";

import sortArray from "../utils/sortArray";

import MeetingCard from "./MeetingCard";

import "../style/MeetingsList.css";


export default function MeetingsList(props) {
    const meetings = useSelector((state) => state.meetings.data);
    const status = useSelector((state) => state.meetings.status);
    let upcomingMeetings = meetings?.filter((item) => (new Date(item.startTime) > new Date()) && item.title.toLowerCase().includes(props.searchText.toLowerCase()));
    upcomingMeetings = sortArray(upcomingMeetings, "asc");
    return (
        <Flex gap="gap.medium" className="rowMeeting">
            {(status !== "success") ? <Skeleton className="rowMeeting" animation="pulse">
                <Skeleton.Shape className="meetingCard" style={{ marginRight: 15 }} />
                <Skeleton.Shape className="meetingCard" style={{ marginRight: 15 }} />
                <Skeleton.Shape className="meetingCard" style={{ marginRight: 15 }} />
            </Skeleton> :
                ((upcomingMeetings?.length > 0) ? upcomingMeetings.slice(0, 3).map(
                    (meeting, key) => (<MeetingCard key={key} meeting={meeting} />)
                )
                    : "")
            }
        </Flex>
    )
}