import { Menu, tabListBehavior } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import sortArray from "../utils/sortArray";



export default function MenuChain(props: any) {
    const key = props.index;
    const meetings: any = useSelector((state: RootStateOrAny) => state.meetings?.data);
    const chains: any = useSelector((state: RootStateOrAny) => state.chains?.data);

    const searchText = props.searchText;
    const setActiveChainName = props.setActiveChainName;
    let activeChainId = props.activeChainId;
    useEffect(() => {
        const firstChain = chains?.at(0)?.title;
        setActiveChainName(firstChain);
    }, [])
    const token = "Bearer " + useSelector((state: RootStateOrAny) => state.credential.data.id_token);
    const [firstLoad, setFirstLoad] = useState(true);
    if (firstLoad && chains?.at(0)?.title) {
        setActiveChainName(chains?.at(0)?.title);
        setFirstLoad(false);
    }

    return (
        <Menu defaultActiveIndex={0} accessibility={tabListBehavior} primary vertical className="menuLeft" style={{ borderRadius: 0, borderTop: "none", paddingTop: 0, }} >
            {chains?.map((item: any, index: any) => {
                let chainId = item.id;
                let filteredMeetings = (meetings?.filter((item: any) => item.chain_id === chainId));
                return (

                    <Menu.Item index={index} key={index} onClick={() => {
                        setActiveChainName(item.title);
                        let activeChain = chains?.find((chain: any) => chain.title === item.title);
                        if (activeChain) {
                            activeChainId = activeChain.id;
                        }
                        let chainsMeetings = meetings?.filter((item: any) => item.chain_id === activeChainId && item.title.toLowerCase().includes(searchText.toLowerCase()));
                        chainsMeetings = sortArray(chainsMeetings);
                        let meetingsIdArr = chainsMeetings?.map((meeting: any) => meeting.id);
                        props.updateData(token, { meetings: meetingsIdArr });

                    }} >
                        <Menu.ItemContent className="itemContent">{item.title + " (" + filteredMeetings?.length + ")"}</Menu.ItemContent>
                    </Menu.Item>
                )
            }
            )}
        </Menu>
    );
}
